<template>
  <a-card :border="false">
    <a-button
      class="m-b-30"
      type="primary"
      v-has:addLogistics="
        () => {
          open();
        }
      "
      >添加快递公司</a-button
    >
    <a-form layout="inline" :model="search">
      <a-form-item label="快递公司名称">
        <a-input
          v-model:value="search.keyword"
          placeholder="输入关键字进行搜索"
          style="width:220px"
        ></a-input>
      </a-form-item>
      <a-form-item label="状态">
        <a-select v-model:value="search.status" style="width:120px">
          <a-select-option value="">全部</a-select-option>
          <a-select-option :value="1">启用</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button
          type="primary"
          @click="
            search.page = 1;
            getExpressList();
          "
          >搜索</a-button
        >
      </a-form-item>
      <a-form-item>
        <a-button type="primary" ghost @click="reset()">重置</a-button>
      </a-form-item>
    </a-form>

    <a-table
      :loading="loading"
      class="m-t-20"
      :columns="columns"
      :data-source="list"
      rowKey="id"
      bordered
      :pagination="pagination"
      @change="changePage"
    >
      <!-- <template #action="{record}">
                 <div class="s_flex_ail">
                     <a-button type="primary" ghost @click="open(3,record)">详情</a-button>
                      <a-button type="primary" class="m-l-15" @click="open(2,record)">编辑</a-button>
                 </div>
             </template> -->
      <template #switchStatus="{record}">
        <a-switch
          v-model:checked="record.is_show"
          checked-children="启用"
          un-checked-children="禁用"
          @change="changeSwitch($event, record)"
        />
      </template>
      <template #sort="{record}">
        <a-input
          v-model:value="record.sort"
          placeholder="排序号"
          style="width:120px;text-align:center"
          @blur="editSort(record)"
        ></a-input>
      </template>
    </a-table>

    <a-modal
      v-model:visible="showVisible"
      :width="1000"
      title="添加快递公司"
      centered
      :footer="null"
      destroyOnClose
    >
      <a-form layout="inline">
        <a-form-item label="快递公司名称">
          <a-input
            v-model:value="express_search.keyword"
            placeholder="请输入快递公司名称"
            style="width:220px"
          ></a-input>
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            @click="
              express_search.page = 1;
              getExpressLogistics();
            "
            >搜索</a-button
          >
        </a-form-item>
      </a-form>
      <a-table
        :loading="companyLoading"
        class="m-t-20"
        :columns="columnsCompany"
        :data-source="companyList"
        rowKey="code"
        bordered
        :pagination="companyPagination"
        @change="changeCompanyPage"
      >
        <template #action="{record}">
          <a-button
            type="primary"
            :ghost="!record.is_add"
            :disabled="record.is_add"
            @click="selectCompany(record)"
            >{{ !record.is_add ? "添加" : "已添加" }}</a-button
          >
        </template>
      </a-table>
    </a-modal>

    <!-- <a-modal v-model:visible="showVisible" :width="600"  :title="(handleType==1?'添加快递公司':(handleType==2?'编辑快递公司':'查看快递公司'))" :footer="null" centered  destroyOnClose>
                <a-form :model="form" :rules="rules" ref="formRef" :labelCol="{span:5}" :wrapperCol="{span:19}">
                  <a-form-item label="快递公司名称" name="name">
                     <a-input v-model:value="form.name" placeholder="请输入快递公司名称" :readonly="handleType==3" :maxlength="50" style="width:70%"></a-input>
                     <span class="m-l-10 s_c999">{{form.name.length || 0}} / 50</span>
                  </a-form-item>
                  <a-form-item label="快递公司编码" name="code">
                     <a-input v-model:value="form.code" placeholder="请输入快递公司编码" :readonly="handleType==3" style="width:70%"></a-input>
                  </a-form-item>
                  <a-form-item label="排序">
                     <a-input v-model:value="form.sort" placeholder="请输入快递公司排序" :readonly="handleType==3" style="width:70%"></a-input>
                  </a-form-item>
                  <a-form-item label="状态">
                      <a-radio-group name="radioGroup" v-model:value="form.is_show" :disabled="handleType==3">
                         <a-radio :value="1">启用</a-radio>
                         <a-radio :value="0">禁用</a-radio>
                      </a-radio-group>
                  </a-form-item>
                </a-form>
                <div class="s_flex_ail" style="text-align:right" v-if="handleType!=3">
                     <a-button type="primary" ghost @click="close()">取消</a-button>
                      <a-button type="primary" class="m-l-15" @click="handleOk()">确定</a-button>
                </div>
          </a-modal> -->
  </a-card>
</template>
<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { message } from "ant-design-vue";
import {
  expressList,
  expressDetail,
  expressUpdate,
  expressAdd,
  expressLogistics
} from "@/api/system.js";
import { $iscode } from "@/utils/app";
const columns = [
  {
    title: "物流公司名称",
    dataIndex: "name",
    align: "center"
  },
  {
    title: "编码",
    dataIndex: "code",
    align: "center"
  },
  {
    title: "排序",
    dataIndex: "sort",
    slots: { customRender: "sort" },
    width: 180,
    align: "center"
  },
  {
    title: "状态",
    dataIndex: "status",
    slots: { customRender: "switchStatus" },
    //    customRender:function({record}){
    //        return record.is_show ==1 ? '启用':"禁用"
    //    },
    width: 180,
    align: "center"
  },
  {
    title: "最后修改时间",
    dataIndex: "update_time",
    align: "center"
  },
  {
    title: "操作人",
    dataIndex: "operator",
    align: "center"
  }
  // {
  //   title: "操作",
  //    dataIndex: "action",
  //    width:180,
  //    slots: { customRender: 'action' }
  // }
];

const columnsCompany = [
  {
    title: "物流公司名称",
    dataIndex: "name"
  },
  {
    title: "编码",
    dataIndex: "code"
  },
  {
    title: "操作",
    dataIndex: "action",
    slots: { customRender: "action" },
    width: 160
  }
];
export default {
  setup() {
    const search = ref({
      keyword: "",
      status: "",
      page: 1,
      limit: 10
    });
    const form = ref({
      name: "",
      code: "",
      sort: "",
      id: "",
      is_show: 1
    });

    const express_search = ref({
      page: 1,
      limit: 10,
      keyword: ""
    });

    const state = reactive({
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        defaultPageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["5", "10", "15", "20", "25"],
        showQuickJumper: true,
        showTotal: (total) => `共 ${total} 条`
      },
      companyPagination: {
        total: 0,
        current: 1,
        pageSize: 5,
        defaultPageSize: 5,
        showSizeChanger: true,
        pageSizeOptions: ["5", "10", "15", "20", "25"],
        showQuickJumper: true,
        showTotal: (total) => `共 ${total} 条`
      },
      list: [],
      showVisible: false,
      companyLoading: false,
      loading: false,
      handleType: 1, //1添加，2编辑，3查看
      rules: {
        name: [{ required: true, message: "请输入快递公司名称!" }],
        code: [{ required: true, message: "请输入快递公司编码!" }]
      },
      companyList: []
    });
    const formRef = ref(null);
    const methods = {
      reset() {
        search.value.keyword = "";
        search.value.type = "";
        search.value.status = "";
      },
      changePage(e) {
        search.value.page = e.current;
        state.pagination.current = e.current;
        state.pagination.pageSize = e.pageSize;
        search.value.limit = e.pageSize;
        methods.getExpressList();
      },
      handleOk() {
        formRef.value
          .validate()
          .then(async () => {
            const hide = message.loading("加载中...");
            let res;
            if (state.handleType == 1) {
              res = await expressAdd({ ...form.value }).then((res) => res.data);
            } else if (state.handleType == 2) {
              res = await expressUpdate({ ...form.value }).then(
                (res) => res.data
              );
            }
            setTimeout(hide);
            if ($iscode(res)) {
              state.showVisible = false;
              methods.getExpressList();
            } else {
              message.error(res.msg);
            }
          })
          .catch((err) => {
            message.error("完善表单!");
          });
      },
      async open(val, data) {
        express_search.value = {
          page: 1,
          limit: 10,
          keyword: ""
        };
        methods.getExpressLogistics();
        state.showVisible = true;
        // state.handleType = val;
        // if(val==1){
        //   form.value = {
        //                  name:'',
        //                  code:'',
        //                  sort:'',
        //                  id:'',
        //                  is_show:1
        //              };
        //   state.showVisible = true
        // }else{
        //     const hide = message.loading('加载中...')
        //     let res = await expressDetail({id:data.id}).then(res=>res.data)
        //     setTimeout(hide)
        //      if($iscode(res)){
        //        form.value = res.data;
        //        state.showVisible = true
        //      }else{
        //       message.error(res.msg)
        //    }

        // }
      },
      close() {
        form.value = {
          name: "",
          code: "",
          sort: "",
          id: "",
          is_show: 1
        };
        state.showVisible = false;
      },
      async getExpressList() {
        state.loading = true;
        state.list = [];
        let res = await expressList({ ...search.value }).then(
          (res) => res.data
        );
        state.loading = false;
        if ($iscode(res)) {
          state.list = res.data.data || [];
          if (state.list.length != 0) {
            state.list.forEach((item) => {
              item.is_show = !!item.is_show;
            });
          }
          state.pagination.current = res.data.current_page || 1;
          state.pagination.total = res.data.total || 0;
        } else {
          message.error(res.msg);
        }
      },
      changeCompanyPage(e) {
        express_search.value.page = e.current;
        state.companyPagination.current = e.current;
        state.companyPagination.pageSize = e.pageSize;
        express_search.value.limit = e.pageSize;
        methods.getExpressLogistics();
      },
      async changeSwitch(e, item) {
        const hide = message.loading("加载中...");
        let res = await expressUpdate({
          ...item,
          is_show: Number(item.is_show)
        }).then((res) => res.data);
        setTimeout(hide);
        if ($iscode(res)) {
          methods.getExpressList();
        } else {
          message.error(res.msg);
        }
      },
      async editSort(item) {
        //  console.log(item)
        const hide = message.loading("加载中...");
        let res = await expressUpdate({ ...item, sort: item.sort }).then(
          (res) => res.data
        );
        setTimeout(hide);
        if ($iscode(res)) {
          methods.getExpressList();
        } else {
          message.error(res.msg);
        }
      },
      async getExpressLogistics() {
        state.companyLoading = true;
        let res = await expressLogistics({ ...express_search.value }).then(
          (res) => res.data
        );
        state.companyLoading = false;
        if ($iscode(res)) {
          console.log(11);
          state.companyList = res.data.data || [];
          state.companyPagination.total = Number(res.data.total) || 0;
          state.companyPagination.current = Number(res.data.page);
        } else {
          message.error(res.msg);
        }
      },
      async selectCompany(record) {
        record.is_add = true;
        const hide = message.loading("加载中...");
        let res = await expressAdd({
          ...record,
          is_show: Number(record.is_add)
        }).then((res) => res.data);
        setTimeout(hide);
        if ($iscode(res)) {
          methods.getExpressList();
          state.showVisible = false;
        } else {
          message.error(res.msg);
        }
      }
    };

    onMounted(() => {
      methods.getExpressList();
    });
    return {
      ...toRefs(state),
      search,
      ...methods,
      columns,
      form,
      formRef,
      columnsCompany,
      express_search
    };
  }
};
</script>
